import React, { useEffect, useRef, useState } from 'react';
import OpenAI from 'openai';

// Initialize the OpenAI client
const openai = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
	dangerouslyAllowBrowser: true,
});

function App() {

  const [isStreaming, setIsStreaming] = useState(false);
  const [output, setOutput] = useState('');
  const [file, setFile] = useState(null)
  const [fileContent, setFileContent] = useState(null)
  const [aiFileContent, setAIFileContent] = useState(null)

  const [assistant] = useState(
		'asst_BJsv5tjzHiqh0Bt9PKZeDKCL'
	);

  const handleChooseFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFile(e.target.files[0])
  }

  const handleUpload = async () => {
    const fileObj = await openai.files.create({
      // file: fs.createReadStream(file),
      file: file,
      purpose: "assistants",
    });
    interactWithAssistant(fileObj.id)
    getOriginalFileContent(file)
  }

  const getOriginalFileContent = async () => {
    if(file){
        let fileContent = await file.text()
        setFileContent(fileContent)
    }
  }

  const interactWithAssistant = async (fileId) => {
		//Create a thread
		const thread = await openai.beta.threads.create(
      {
      tool_resources: {
        "file_search": {
          "vector_store_ids": ["vs_bJv5nbIhzIi74vEquRD5GoVC"]
        }
      }
    }
    );
		console.log('Thread created with id:', thread.id);

		console.log('Creating message...');
		await openai.beta.threads.messages.create(thread.id, {
			role: 'user',
			content: 'Analyze the attached notes according to your instructions.',
      attachments: [
        {
          file_id: fileId, 
          tools: [
            // {
            //   type: 'file_search'
            // },
            {
              type: 'code_interpreter'
            },
          ] 
        },
        // {
        //   file_id: 'file-elbyvATbesA2DhKFa7r0yOha', 
        //   tools: [{type: 'file_search'}] 
        // },
        // {
        //   file_id: 'file-EPIOQhzTe1jSY7Db10A6Vpp8', 
        //   tools: [{type: 'file_search'}] 
        // },
        // {
        //   file_id: 'file-hlKWYDFYkaE3wIEwFdE6rjhP', 
        //   tools: [{type: 'file_search'}] 
        // },
        // {
        //   file_id: 'file-YhlWNsdLaupSxI6bhU8U9fQQ', 
        //   tools: [{type: 'file_search'}] 
        // },
        // {
        //   file_id: 'file-cUMfTxEmQoSwLf2G5NuqlfEq', 
        //   tools: [{type: 'file_search'}] 
        // },
        // {
        //   file_id: 'file-44sWAlcSTtbWXl0FmL4pfOkG', 
        //   tools: [{type: 'file_search'}] 
        // }
      ]
		});
		
		setIsStreaming(true);

		const run = new Promise((resolve, reject) => {
			console.log('Starting stream...');
			openai.beta.threads.runs
				.stream(thread.id, {
					assistant_id: assistant,
				})
        .on('textCreated', (text) => {
					console.log('Text created:', text);
				})
				.on('textDelta', (textDelta, snapshot) => {
          if(typeof textDelta.value == 'undefined' && textDelta.annotations){
            if(textDelta.annotations[0].file_path.file_id){
              // console.log(textDelta.annotations[0].file_path.file_id)
              getNewFile(textDelta.annotations[0].file_path.file_id)
            }
          }else if(textDelta.value){
            setOutput((prevOutput) => `${prevOutput}${textDelta.value}`);
          }
        })
        .on('toolCallCreated', (toolCall) => {
					console.log('Tool call created:', toolCall);
					// setOutput(
					// 	(prevOutput) => `${prevOutput}\nassistant > ${toolCall.type}\n\n`
					// );
				})
				.on('toolCallDelta', (toolCallDelta, snapshot) => {
					// console.log('Tool call delta:', toolCallDelta);
					// if (toolCallDelta.type === 'file_search') {
          //   console.log("file_search")
          //   console.log(toolCallDelta)
					// 	if (toolCallDelta.file_search.input) {
					// 		setOutput((prevOutput) =>`${prevOutput}${toolCallDelta.file_search.input}`);
					// 	}
					// 	if (toolCallDelta.file_search.outputs) {
					// 		setOutput((prevOutput) => `${prevOutput}\noutput >\n`);
					// 		toolCallDelta.file_search.outputs.forEach((output) => {
					// 			if (output.type === 'logs') {
					// 				setOutput((prevOutput) => `${prevOutput}\n${output.logs}\n`);
					// 			}
					// 		});
					// 	}
					// }
          // if (toolCallDelta.type === 'code_interpreter') {
          //     // console.log("--code_interpreter--")
          //     // console.log(toolCallDelta.code_interpreter)
          //     if (toolCallDelta.code_interpreter.input) {
          //       // setOutput((prevOutput) =>`${prevOutput}${toolCallDelta.code_interpreter.input}`);
          //     }
          //     if (toolCallDelta.code_interpreter.outputs) {
          //         toolCallDelta.code_interpreter.outputs.forEach(output => {
          //             if (output.type === "logs") {
          //                 // setOutput((prevOutput) => `${prevOutput}\n${output.logs}\n`);
          //             }
          //             // console.log("--Output type--" + output.type)
          //         });
          //     }
          // }
				})
        .on('messageDone', async (event) => {
            console.log('Done')
            console.log(event);

            if (event.content && event.content[0] && event.content[0].type === "text") {
              const { text } = event.content[0];
              const { annotations } = text;
              
              if (annotations && annotations.length > 0) {
                let citations = [];
                let index = 0;

                for (let annotation of annotations) {
                  text.value = text.value.replace(annotation.text, "[" + index + "]");
                  
                  const { file_citation } = annotation;
                  if (file_citation) {
                    try {
                      const citedFile = await openai.files.retrieve(file_citation.file_id);
                      citations.push("[" + index + "]" + citedFile.filename);
                    } catch (error) {
                      console.error("Error retrieving file:", error);
                    }
                  }
                  index++;
                }

                console.log(citations.join("\n"));
              } else {
                console.log("No annotations found.");
              }
            } else {
              console.log("No valid text content found.");
            }

            // if (event.content[0].type === "text") {
            //   const { text } = event.content[0];
            //   const { annotations } = text;
            //   const citations = [];
        
            //   let index = 0;
            //   for (let annotation of annotations) {
            //     text.value = text.value.replace(annotation.text, "[" + index + "]");
            //     const { file_citation } = annotation;
            //     if (file_citation) {
            //       const citedFile = await openai.files.retrieve(file_citation.file_id);
            //       citations.push("[" + index + "]" + citedFile.filename);
            //     }
            //     index++;
            //   }
        
            //   // console.log(text.value);
            //   console.log(citations.join("\n"));
            // }

            setIsStreaming(false)
        })
				.on('error', (error) => {
					console.log('Error:', error);
					setIsStreaming(false);
				});
		});
	};

  const getNewFile = async (fileId) => {
    const newFile = await openai.files.retrieve(fileId);
    const newFileName = newFile.filename
    const newFileContent = await openai.files.content(fileId);
    const newFileText = await newFileContent.text()
    if(newFileText){
      setAIFileContent(newFileText)
    }
    // console.log(newFileText)
    const blob = new Blob([newFileText], { type: 'text/plain' }); // Adjust the type depending on the file type
    const url = window.URL.createObjectURL(blob);
    // Create a temporary anchor element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = newFileName; // Set the file name
    document.body.appendChild(a);
    a.click();

    // Clean up and remove the anchor element
    a.remove();
    window.URL.revokeObjectURL(url);
  }

  return (
    <div className="App">
        <input 
            type="file"
            // tabIndex="-1"
            // ref={inputRef}
            // style={{display: 'none'}}   
            onChange={handleChooseFile}
        />
        <button
          onClick={() =>
            handleUpload()
          }
        >Upload</button>
        <div style={{maxHeight: '300px', overflow: 'auto', margin:'24px'}}>
          <b>AI Response:</b>
          <br/>
          {output}
        </div>
        <div style={{display:'flex'}}>
            <div style={{flex:'1', margin:'24px'}}>
                {
                  fileContent ?
                  <>
                    {
                      fileContent
                    }
                  </>
                  : 'Original notes shows up here'
                }
            </div>
            <div style={{flex:'1', margin:'24px'}}>
            {
                  aiFileContent ?
                  <>
                    {
                      aiFileContent
                    }
                  </>
                  : 'AI notes shows up here'
                }
            </div>
        </div>
    </div>
  );
}

export default App;
